<template>
  <Page
    has-actions
    :subtitle="`${total} ${$tc('result', total <= 1 ? 1 : 2)}`"
    :loading="categories_loading"
  >
    <div slot="actions">
      <div class="field has-addons">
        <div class="is-flex mr-2">
          <b-button
            v-if="$device.mobile"
            size="is-small"
            rounded
            @click="showMobile()"
          >
            <b-icon
              icon="magnify"
              size="is-small"
            />
          </b-button>
          <b-input
            ref="search"
            v-model="search"
            :size="$device.mobile ? 'is-small' : ''"
            :class="{ expand: searchFocused, 'hide-search': $device.mobile }"
            :placeholder="$t('search')"
            icon="magnify"
            rounded
            @blur="hideMobile()"
          />
        </div>
        <b-dropdown
          v-if="$can('create', 'blog')"
          aria-role="list"
          position="is-bottom-left"
          scrollable
          max-height="400px"
          append-to-body
        >
          <div slot="trigger">
            <b-button
              icon-left="dots-vertical"
              :size="$device.mobile ? 'is-small' : ''"
              type="is-default"
              rounded
            />
          </div>

          <b-dropdown-item
            v-if="$can('create', 'blog')"
            aria-role="listitem"
            has-link
          >
            <router-link to="/blog/categories/create">
              <b-icon
                icon="plus"
                size="is-small"
              />
              {{ $t("new_category") }}
            </router-link>
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
    <Card
      v-if="$can('read', 'blog')"
      content-class="c-table"
    >
      <b-table
        :loading="categories_loading"
        :data="itemsFiltered"
        paginated
        :per-page="perPage"
        :current-page="page"
        pagination-position="top"
        :total="total"
        :row-class="
          (row, index) => (row.id == updated_category ? 'bg-updated' : '')
        "
      >
        <template v-for="head in headers">
          <b-table-column
            v-slot="props"
            :key="head.value"
            :label="head.text"
            :field="head.value"
            :sortable="head.sortable"
            :width="head.width"
            :centered="head.centered"
            header-class="sticky-header"
          >
            <template v-if="head.value == 'id'">
              <router-link
                v-if="$can('read', 'blog') || $can('update', 'blog')"
                :to="`/blog/categories/${props.row.id}`"
              >
                {{ props.row.id }}
              </router-link>
              <span v-else>{{ props.row.id }}</span>
            </template>
            <template v-else-if="head.value == 'category'">
              <span
                :class="{
                  'has-text-weight-semibold': props.row.level == 1,
                  'pl-4': props.row.level == 2,
                  'pl-6': props.row.level == 3
                }"
              >{{ props.row.category }}</span>
            </template>
            <template v-else-if="head.value == 'count'">
              {{ props.row.count }}
            </template>
            <template v-else-if="head.value == 'active'">
              <b-icon
                :key="props.row.active"
                :icon="props.row.active ? 'check' : 'close'"
                custom-size="mdi-18px"
                :type="props.row.active ? 'is-success' : 'is-danger'"
              />
            </template>
            <template v-else-if="head.value == 'actions'">
              <b-dropdown
                :key="props.row.id"
                aria-role="list"
                position="is-bottom-left"
                append-to-body
              >
                <div slot="trigger">
                  <b-icon
                    icon="dots-vertical"
                    size="is-small"
                    class="is-clickable"
                  />
                </div>

                <b-dropdown-item
                  v-if="$can('read', 'blog') || $can('update', 'blog')"
                  aria-role="listitem"
                  has-link
                >
                  <router-link :to="`/blog/categories/${props.row.id}`">
                    <b-icon
                      icon="eye"
                      size="is-small"
                    />
                    {{ $t("view") }}
                  </router-link>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="
                    props.row.active &&
                      props.row.count == 0 &&
                      $can('delete', 'blog')
                  "
                  aria-role="listitem"
                  @click="confirmDeleting(props.row.id)"
                >
                  <b-icon
                    icon="delete"
                    type="is-danger"
                    size="is-small"
                  />
                  {{ $t("delete") }}
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="!props.row.active && $can('update', 'blog')"
                  aria-role="listitem"
                  @click="restoreCategories(props.row.id)"
                >
                  <b-icon
                    icon="refresh"
                    type="is-success"
                    size="is-small"
                  />
                  {{ $t("restore") }}
                </b-dropdown-item>
              </b-dropdown>
            </template>
            <template v-else>
              {{ props.row[head.value] }}
            </template>
          </b-table-column>
        </template>
        <EmptyBlock
          slot="empty"
          icon="shape"
        />
      </b-table>
      <Pagination
        :per-page="perPage"
        :per-pages="perPages"
        :total="total"
        :current-page="page"
        @update-per-page="val => (perPage = val)"
        @update-current-page="val => (page = val)"
      />
    </Card>
  </Page>
</template>

<script>
export default {
  data() {
    return {
      searchFocused: false,
      categories_loading: true,
      updated_category: null,
      search: "",
      total: 0,
      perPages: [50, 100, 200, 500],
      perPage: 50,
      page: 1,
      headers: [
        {
          text: this.$i18n.t("id"),
          value: "id",
          width: 60
        },
        {
          text: this.$i18n.tc("category", 1),
          value: "category"
        },
        {
          text: this.$i18n.tc("article", 2),
          value: "count",
          centered: true,
          width: 100
        },
        {
          text: this.$i18n.t("rank"),
          value: "rank",
          width: 100
        },
        {
          text: this.$i18n.tc("active", 1),
          value: "active",
          width: 100
        },
        {
          text: this.$i18n.t("actions"),
          value: "actions",
          width: 80,
          centered: true
        }
      ],
      items: [],
      itemsFiltered: [],
      categories: []
    };
  },
  watch: {
    search() {
      this.filterSearch();
    }
  },
  created() {
    this.updateTitle(this.$tc("category", 2));
    this.$bus.$on("save-category", ({ category }) => {
      this.search = "";
      this.getCategories();
      this.updated_category = category.id;
      setTimeout(() => {
        this.updated_category = null;
      }, 2000);
    });
  },
  destroyed() {
    this.$bus.$off("save-category");
  },
  mounted() {
    this.getCategories();
  },
  methods: {
    showMobile() {
      if (this.$device.mobile) {
        this.searchFocused = true;
        this.$refs.search.focus();
      }
    },
    hideMobile() {
      if (this.$device.mobile) {
        this.searchFocused = false;
      }
    },
    confirmDeleting(id) {
      this.$buefy.dialog.confirm({
        message: `<b>${this.$t("are_you_sure")}</b>`,
        confirmText: this.$t("confirm"),
        cancelText: this.$t("cancel"),
        type: "is-danger",
        onConfirm: () => this.deleteCategories(id)
      });
    },
    filterSearch() {
      if (this.search.length) {
        this.itemsFiltered = this.items.filter(
          a =>
            JSON.stringify(a)
              .toLowerCase()
              .indexOf(this.search.toLowerCase()) != -1
        );
      } else {
        this.itemsFiltered = this.items;
      }
      this.total = this.itemsFiltered.length;
    },
    getCategories(params = null) {
      if (this.$can("read", "catalog")) {
        this.categories_loading = true;
        let query = "";
        if (params && params.active !== undefined && !params.active)
          query += "?active=0";

        this.$axios
          .get("blog/categories" + query)
          .then(res => {
            let arr = [];
            for (let i = 0; i < res.data.length; i++) {
              const cat = res.data[i];
              cat.level = 1;
              arr.push(cat);
              for (let j = 0; j < cat.children.length; j++) {
                const subCat = cat.children[j];
                subCat.level = 2;
                arr.push(subCat);
              }
            }
            this.items = arr;
            this.filterSearch();
          })
          .catch(e => this.clientError(e))
          .finally(() => (this.categories_loading = false));
      }
    },
    deleteCategories(id) {
      this.$axios
        .delete("blog/categories/" + id)
        .then(() => {
          this.getCategories();
          this.$root.notify(this.$t("successfully_deleted"), "is-success");
        })
        .catch(e => this.clientError(e));
    },
    restoreCategories(id) {
      this.$axios
        .patch("blog/categories/" + id + "/restore")
        .then(() => {
          this.getCategories();
          this.$root.notify(this.$t("successfully_restored"), "is-success");
        })
        .catch(e => this.clientError(e));
    }
  }
};
</script>
